import { template as template_fa7f59d24b6b4efd9e83381045c3c20c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_fa7f59d24b6b4efd9e83381045c3c20c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
