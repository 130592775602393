import { template as template_b599678b8ab842a2a6a5f30fd17ad128 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b599678b8ab842a2a6a5f30fd17ad128(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
