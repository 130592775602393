import { template as template_71401348dbc54763a76925e7faa7a3ed } from "@ember/template-compiler";
const WelcomeHeader = template_71401348dbc54763a76925e7faa7a3ed(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
